import React from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";

/**
 * Generic loading component
 * @param {*} props 
 * @author BrianCastor
 */
export const LoadingDisplay = props => {
    return (
        <div style={{textAlign:'center', marginTop:'100px'}}>
            <CircularProgress color="primary" size={80}/>
            <p>Loading...</p>
        </div>
    );
}